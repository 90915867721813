import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import Field from 'components/Form/Field';

export const ObservationWrapper = styled.div`
  ${({ theme: { border, breakpoints, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    min-width: 520px;
    width: 100%;
    max-width: ${breakpoints.tabletMD};
    gap: ${space.xl};
    padding: ${space.lg};
    border-radius: ${border.radius.md};
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      min-width: 400px;
      width: 100%;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      min-width: 300px;
      width: 100%;
    }
  `}
`;

export const ObservationField = styled(Field)`
  padding: 0;

  ${({ quillProps: { readOnly } }) =>
    readOnly &&
    css`
      .quill .ql-toolbar {
        display: none;
      }
      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 1px solid #ccc;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    `};
`;
