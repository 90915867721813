import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form';

import history from 'core/utils/history';

/**
 * Modules: place the reducers here
 */
import root from '../root';
import albums from '../albums';
import permissions from '../permissions';
import sophiaFilters from '../sophia';
import sponteFilters from '../sponte';
import integrations from '../integrations';
import {
  availabilities,
  recurrentPlans,
  recurrentBills,
  recurrentProducts,
  recurrentForms,
  enrollmentPlans,
  schoolProducts,
  wallets,
  shop,
  negotiations,
} from '../edupay';
import events from '../events';
import filters from '../filters';
import surveys from '../surveys';
import myAccount from '../myAccount';
import home from '../home';
import panel from '../panel';
import summarydayClassrooms from '../summarydayClassrooms';
import reports from '../reports';
import headquarters from '../headquarters';
import dailySummaries from '../dailySummaries';
import responsibleProfiles from '../responsibleProfiles';
import studentProfiles from '../studentProfiles';
import importWeb from '../importWeb';
import editDynamicModules from '../editDynamicModules';
import dynamicModule from '../dynamicModule';
import { inviteEmptyState } from '../invite';
import registerAccount from '../registerAccount';
import messages from '../messages';
import omniChannel from '../messages/omniChannel';
import onboarding from '../onboarding';
import billingPanel from '../billingPanel';
import moodTrackers from '../moodTrackers';
import handouts from '../handouts';
import hubstore from '../hubstore';
import quickAccess from '../quickAccess';
import integrationsMonitor from '../integrationsMonitor';

/**
 * Merges the main reducer with the router state
 */
export default function createReducer() {
  const rootReducer = combineReducers({
    root,
    permissions,
    availabilities,
    sophiaFilters,
    sponteFilters,
    albums,
    integrations,
    recurrentProducts,
    recurrentPlans,
    recurrentBills,
    recurrentForms,
    enrollmentPlans,
    schoolProducts,
    wallets,
    form: formReducer,
    events,
    filters,
    surveys,
    myAccount,
    home,
    panel,
    summarydayClassrooms,
    reports,
    headquarters,
    dailySummaries,
    responsibleProfiles,
    studentProfiles,
    shop,
    importWeb,
    editDynamicModules,
    dynamicModule,
    inviteEmptyState,
    registerAccount,
    messages,
    omniChannel,
    onboarding,
    router: connectRouter(history),
    negotiations,
    billingPanel,
    moodTrackers,
    handouts,
    hubstore,
    quickAccess,
    integrationsMonitor,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);

  return mergeWithRouterState(rootReducer);
}
