import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import withAppContext from 'core/hoc/withAppContext';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { isMobile } from 'core/utils/viewport';

import Toast from 'components/Toast';
import { Props } from './types';

const FolderForm = ({ history: { goBack } }: Props) => {
  const { t } = useTranslation(['storage', 'common']);

  const { screenWidth } = useWindowDimensions();

  const [isMobileScreen, setIsMobile] = useState(isMobile(screenWidth));
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Move to context

  const tBase = useCallback((key: string) => t(`folder_form.${key}`), [t]);

  const stepInfos = useMemo(
    () => [
      {
        step: 1,
        title: tBase('info_step.title'),
        isActive: currentStep === 1,
        isDisabled: false,
      },
      {
        title: tBase('recipients_step.title'),
        isActive: currentStep === 2,
        isDisabled: isNextStepDisabled,
        step: 2,
      },
    ],
    [currentStep, isNextStepDisabled, tBase]
  );

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        width="min-content"
        mx="auto"
        gap="sm"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            isMobile={isMobileScreen}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, stepInfos]
  );

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobile(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  return (
    <>
      <FullScreenModal
        isOpen
        title={tBase('title')}
        headerContent={getHeaderContent}
        onClose={goBack}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mt="xl"
          overflow="hidden"
          pl={{ mobileXS: 'xs2', desktopLG: 'xl5', desktopXL: '15%' }}
        ></Box>
      </FullScreenModal>

      <Toast />
    </>
  );
};

export default withAppContext(withRouter(FolderForm));
