import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  DefaultThemeProps,
  FullScreenModal,
  Text,
  SingleDate,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import withAppContext from 'core/hoc/withAppContext';
import { useTheme } from 'styled-components';

import moment from 'moment';
import formatDatetime from 'core/utils/formatDatetime';

import DiaryActions from 'store/dailySummaries/actions';
import Toast from 'components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import StudentsList from './StudentsList';
import { DiaryFormContext } from 'core/contexts/DiaryForm';
import { DiaryState } from 'store/dailySummaries/types';
import { NotificationModal } from './NotificationModal';
import { DiscardModal } from './DiscardModal';
import { SectionList } from './SectionsList';
import EmptyState from 'components/EmptyState';
import { IMAGES_JOURNAL_DOMAIN_URL } from 'core/constants/index';
import { InfoConfirmModal } from './InfoConfirmModal';

import { Props, SelectStudentDiscardModal } from './types';

const DiaryForm = ({ classroomId, history: { goBack }, appContext }: Props) => {
  const [showInfoConfirmModal, setShowInfoConfirmModal] = useState(
    appContext.showDiaryModal
  );
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [selectStudentDiscardModal, setSelectStudentDiscardModal] =
    useState<SelectStudentDiscardModal>({
      confirmAction: null,
    });
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const { border, colors } = useTheme() as DefaultThemeProps;

  const { fetchActivesDiarySections, fetchClassroom } = DiaryActions;
  const dispatch = useDispatch();

  const {
    form: { dirty, isValid },
    date,
    setDate,
    studentsIdsSelecteds,
    setStudentsIdsSelecteds,
    isSaving,
  } = useContext(DiaryFormContext);

  const {
    classroom: {
      attributes: {
        name,
        student_profiles: { data },
      },
    },
    classroomIncluded,
    studentDiaryForm,
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const getClassroomIncludedNames = (type) => {
    return classroomIncluded.find((info) => info.type === type)?.attributes
      .name;
  };

  const educationalStageName = getClassroomIncludedNames('educational_stage');
  const headquarterName = getClassroomIncludedNames('headquarter');

  const schoolSubtitle =
    !educationalStageName || !headquarterName
      ? ''
      : `${headquarterName} / ${educationalStageName}`;

  const { t } = useTranslation(['diary', 'common']);

  const tBase = useCallback((key: string) => t(`form.${key}`), [t]);

  const isWithinLastSevenDays = (day: moment.Moment) =>
    !day.isBetween(
      moment().subtract(8, 'days'),
      moment().add(1, 'days'),
      'day'
    );

  const handleChangeDate = (date: moment.Moment) => {
    if (!date) return setDate(moment(new Date()));

    return setDate(date);
  };

  const handleSelectStudents = (ids: string[]) => {
    const showModalWhenChangeFromSingle =
      dirty &&
      studentsIdsSelecteds.length === 1 &&
      !!Object.keys(studentDiaryForm || {}).length;

    const studentHasDiary =
      ids.length === 1 &&
      data.find((student) => student.id === ids[0])?.attributes
        ?.has_daily_summary;

    const showModalWhenChangeFromMultiple =
      dirty && studentsIdsSelecteds.length > 1 && studentHasDiary;

    if (showModalWhenChangeFromSingle || showModalWhenChangeFromMultiple) {
      setSelectStudentDiscardModal({
        confirmAction: () => setStudentsIdsSelecteds(ids),
      });
    } else {
      setStudentsIdsSelecteds(ids);
    }
  };

  useEffect(() => {
    dispatch(fetchActivesDiarySections({ classroomId }));
  }, [classroomId, dispatch, fetchActivesDiarySections]);

  useEffect(() => {
    dispatch(fetchClassroom(classroomId, date.format('YYYY-MM-DD')));
  }, [classroomId, date, dispatch, fetchClassroom]);

  const disableSaveButton = [
    !dirty,
    !isValid,
    isSaving,
    !studentsIdsSelecteds.length,
  ].some(Boolean);

  const footer = (
    <Button
      data-testid="notify-button"
      ml="auto"
      disabled={disableSaveButton}
      onClick={() => setShowNotificationModal(true)}
    >
      {t('common:button.save')}
    </Button>
  );

  const capitalizeDate = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  const shouldOpenDiscardModal = dirty && studentsIdsSelecteds.length;

  return (
    <>
      <FullScreenModal
        isOpen
        title={tBase('title')}
        onClose={() =>
          shouldOpenDiscardModal ? setShowDiscardModal(true) : goBack()
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mt="xl"
          overflow="hidden"
          pl={{ mobileXS: 'xs2', desktopLG: 'xl5', desktopXL: '15%' }}
        >
          <Box
            display="flex"
            overflow="auto"
            height="100%"
            flexDirection={{ _: 'column', desktopLG: 'row' }}
          >
            <Box display="flex" flex={1} flexDirection="column">
              <Box mb="md">
                <Text variant="title-bold-20" color="neutral.black">
                  {name}
                </Text>
                <Text variant="subtitle-medium-14">{schoolSubtitle}</Text>
              </Box>

              <StudentsList
                studentsIdsSelecteds={studentsIdsSelecteds}
                setStudentsIdsSelecteds={handleSelectStudents}
                students={data}
                isCheckable
                placeholder={tBase('search')}
                multiSelect
              />
            </Box>

            <Box
              display="flex"
              flex={2}
              flexDirection="column"
              ml={{ _: 0, desktopLG: 'lg' }}
              mt={{ _: 'lg', desktopLG: 0 }}
              pr={{ mobileXS: 'xs2', desktopLG: 'xl5', desktopXL: '15%' }}
            >
              <Box display="flex" justifyContent="space-between" mb="sm">
                <Text
                  variant="title-bold-20"
                  color="neutral.black"
                  mb={0}
                  mr="sm"
                >
                  {capitalizeDate(formatDatetime(date, tBase('date')))}
                </Text>

                <SingleDate
                  id="diary-date"
                  value={date}
                  handleChange={handleChangeDate}
                  handleOutsideRange={(day) => isWithinLastSevenDays(day)}
                />
              </Box>

              {!studentsIdsSelecteds.length ? (
                <EmptyState
                  imgUrl={IMAGES_JOURNAL_DOMAIN_URL.selectStudentInfo}
                  message={
                    <Text variant="body-bold-14">
                      {t('form.empty_selected_students')}
                    </Text>
                  }
                />
              ) : (
                <SectionList />
              )}
            </Box>
          </Box>
        </Box>

        <Box
          py="sm"
          px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
          borderTop={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
          position="fixed"
          bottom={0}
          width="100%"
          backgroundColor={colors.neutral.white}
        >
          {footer}
        </Box>
      </FullScreenModal>

      <NotificationModal
        isOpen={showNotificationModal}
        onClose={() => setShowNotificationModal(false)}
      />

      <InfoConfirmModal
        isOpen={showInfoConfirmModal && !!studentsIdsSelecteds.length}
        onClose={() => setShowInfoConfirmModal(false)}
      />

      <DiscardModal
        isOpen={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        onConfirmDiscard={goBack}
      />

      <DiscardModal
        isOpen={!!selectStudentDiscardModal.confirmAction}
        onClose={() => setSelectStudentDiscardModal({ confirmAction: null })}
        onConfirmDiscard={() => {
          selectStudentDiscardModal.confirmAction();
          setSelectStudentDiscardModal({ confirmAction: null });
        }}
      />

      <Toast />
    </>
  );
};

export default withAppContext(withRouter(DiaryForm));
